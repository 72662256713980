import React from "react"
import { graphql } from "gatsby"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import SaveIcon from "@material-ui/icons/Save"
import Skeleton from "@material-ui/lab/Skeleton"
import Typography from "@material-ui/core/Typography"
import { CSVLink } from "react-csv"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import ToolLayout from "../../../components/tool_layout"

export const query = graphql`
  query HttpHeadersQuery {
    allToolsJson(filter: { path: { eq: "web/http-headers" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
          }
        }
      }
    }
  }
`

class HttpHeadersState {
  components: Map<string, any> = new Map()
  error: Error | null
}

class HttpHeadersPage extends React.Component<{ data: any }> {
  state: HttpHeadersState

  constructor(props: any) {
    super(props)

    this.state = new HttpHeadersState()
  }

  componentDidMount() {
    this.refresh()
  }

  refresh = () => {
    this.setState({
      components: new Map(),
      error: null,
    })

    fetch(this.getApi())
      .then(response => response.json())
      .then(data => this.setState({ components: data }))
      .catch(err => this.setState({ error: err }))
  }

  getApi = () => {
    if (process.env.NODE_ENV === "production") {
      return "https://api.twubr.com/api/headers"
    }

    return "/api/headers"
  }

  featuresTable = () => {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(this.state.components).map(entry => (
              <TableRow key={entry[0]} hover>
                <TableCell>
                  <Typography variant="subtitle2">{entry[0]}</Typography>
                </TableCell>
                <TableCell>
                  <Box display="flex">
                    <Typography variant="body2" noWrap>
                      {entry[1].toString()}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  contentWidget = () => {
    if (this.state.components.size === 0 && !this.state.error) {
      return (
        <>
          {Array.from(Array(10).keys()).map(index => (
            <Skeleton animation="wave" key={index} />
          ))}
        </>
      )
    }

    if (this.state.error) {
      return (
        <>
          <Box
            width={1}
            display="flex"
            justifyContent="center"
            fontSize="h2.fontSize"
          >
            {this.state.error.message}
          </Box>
          <Box width={1} mt={4} display="flex" justifyContent="center">
            <Button
              color="secondary"
              variant="contained"
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </Box>
        </>
      )
    }

    return this.featuresTable()
  }

  render() {
    if (this.state.components.size === 0 && !this.state.error) {
      return (
        <ToolLayout data={this.props.data}>
          {Array.from(Array(10).keys()).map(index => (
            <Skeleton animation="wave" key={index} />
          ))}
        </ToolLayout>
      )
    }

    return (
      <ToolLayout data={this.props.data}>
        <Box
          width={1}
          mt={2}
          mb={2}
          mr={8}
          display="flex"
          flexDirection="row-reverse"
        >
          <CSVLink
            data={Object.entries(this.state.components)}
            filename="http_headers.csv"
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
            >
              Export to CSV
            </Button>
          </CSVLink>
        </Box>
        <Paper>{this.contentWidget()}</Paper>
      </ToolLayout>
    )
  }
}

export default HttpHeadersPage
